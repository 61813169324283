<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <!-- 框 -->
                    <v-alert :dismissible="false" class="deep-purple accent-2 white--text" dark border="left" icon="fab fa-bitcoin">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10">
                                <span>Blockchain Asset</span>
                                <span class="ml-5">注: 此处是管理硬件钱包 Ledger 的资产通过区块链浏览器查询余额，以太坊的资产添加后可以直接使用，其余链的资产添加后需开发相关API后才可使用。</span>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn small outlined tile @click="addDialog = true">New</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-5 pt-0">
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table dense :headers="headers" :items="dataList" hide-default-footer disable-sort v-if="!loading">
                        <!-- 表数据 -->
                        <template v-slot:body="{ data }">
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="data.id">
                                    <!-- <td>{{ (indexNo - 1) * pageSize + (index + 1) }}</td> -->
                                    <!-- <td>{{ data.id }}</td> -->
                                    <td>{{ data.blockchain }}</td>
                                    <td>{{ data.currency }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.contractAddress != null ? data.contractAddress : '--' }}</td>
                                    <td>
                                        <a :href='data.officialWebsite' target="_blank">
                                            {{ data.officialWebsite }}
                                        </a>
                                    </td>
                                    <td>
                                        <a :href='data.explorerUrl' target="_blank">
                                            {{ data.explorerUrl }}
                                        </a>
                                    </td>
                                    <td>{{ data.tokenDecimal }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!loading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="totalElements>0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="deep-purple accent-2" circle v-model="pageNo" :length="totalPage" @input="getDataList" :total-visible="20">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <!-- 新增模态框 -->
        <v-dialog v-model="addDialog"width="500" persistent>
            <v-card>
                <v-card-title>
                    <span class="fs-24">Add</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="addDialog = false">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="6">
                            <v-text-field label="Blockchain" required clearable v-model="addObj.blockchain" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Currency" required clearable v-model="addObj.currency" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Name" required clearable v-model="addObj.name" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Token Decimal" clearable v-model="addObj.tokenDecimal" type="Number" step="1" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Contract Address (default: empty)" clearable v-model="addObj.contractAddress" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Official Website" clearable v-model="addObj.officialWebsite" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Explorer Url" clearable v-model="addObj.explorerUrl" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="blue--text" outlined @click="addDialog = false" width="75" tile>Cancel</v-btn>
                    <v-btn small class="green--text mr-5" outlined @click="submit()" width="75" tile>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                    // { text: 'No'},
                    { text: 'Blockchain' },
                    { text: 'Currency' },
                    { text: 'Name' },
                    { text: 'Contract Address' },
                    { text: 'Official Website' },
                    { text: 'Explorer Url' },
                    { text: 'Token Decimal' }
                ],
                dataList: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 20,
                date: null,
                indexNo: 0,
                loading: false,
                // 新增模态框
                addDialog: false,
                addObj:{
                    blockchain: null,
                    currency: null,
                    name: null,
                    contractAddress: null,
                    officialWebsite: null,
                    explorerUrl: null,
                    tokenDecimal: 0
                },
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {

        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
            this.getDataList(1);
        },
        created() {

        },
        methods: {
            // 获取访问数据
            getDataList(pageNo) {
                this.loading = true;
                let api = config.baseUrl + '/blockchain/asset/private/list?pageNo=' + (this.pageNo - 1) + '&pageSize=' + this.pageSize;
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.loading = false;
                            this.dataList = response.data.result.dataList;
                            this.totalElements = response.data.result.totalElements;
                            this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                            this.indexNo = pageNo;
                        }
                    },
                )
            },
            // 提交请求
            submit(){
                let api = config.baseUrl + "/blockchain/asset/private/add";
                this.$http.post(api, this.addObj).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.addDialog = false;
                        let addObj = {
                            blockchain: null,
                            currency: null,
                            name: null,
                            contractAddress: null,
                            officialWebsite: null,
                            explorerUrl: null,
                            tokenDecimal: 0
                        };
                        this.addObj = addObj;
                        this.getDataList();
                    } else {
                        this.$store.dispatch("snackbarMessageHandler", data.message);
                    }
                })
            }
        }
    }
</script>